
body {
  margin: 0;
  /* font-family: "var(--font-montserrat)" !important; */
  /* font-family: var(--font-montserrat) !important; */
}

/* * {
  font-family: var(--font-montserrat);
} */

.font {
  font-family: var(--font-montserrat);
}

:root {
  --unnamed-color-e74c3c: #e74c3c;
  --unnamed-color-e1feee: #e1feee;
  --unnamed-color-ffd200: #ffd200;
  --unnamed-color-00b14f: #00b14f;
  --unnamed-color-161616: #161616;
  --unnamed-color-545454: #545454;
  --unnamed-color-ababab: #ababab;
  --unnamed-color-d5d5d5: #d5d5d5;
  --unnamed-color-ebebeb: #ebebeb;
  --unnamed-color-f7f7f7: #f7f7f7;
  --unnamed-color-ffffff: #ffffff;

  /* Font/text values */
  --unnamed-font-family-montserrat: var(--font-montserrat);
  --unnamed-font-style-normal: normal;
  --unnamed-font-weight-medium: medium;
  --unnamed-font-weight-normal: normal;
  --unnamed-font-size-14: 14px;
  --unnamed-font-size-16: 16px;
  --unnamed-font-size-20: 20px;
  --unnamed-character-spacing-0-15: 0.15px;
  --unnamed-character-spacing-0-11: 0.11px;
  --unnamed-character-spacing-0-12: 0.12px;
  --unnamed-line-spacing-24: 24px;
}

/* Character Styles */
.unnamed-character-style-1 {
  font-family: var(--unnamed-font-family-montserrat);
  font-style: var(--unnamed-font-style-normal);
  font-weight: var(--unnamed-font-weight-normal);
  font-size: var(--unnamed-font-size-14);
  line-height: var(--unnamed-line-spacing-24);
  letter-spacing: var(--unnamed-character-spacing-0-11);
  color: var(--unnamed-color-545454);
}
.unnamed-character-style-2 {
  font-family: var(--unnamed-font-family-montserrat);
  font-style: var(--unnamed-font-style-normal);
  font-weight: var(--unnamed-font-weight-normal);
  font-size: var(--unnamed-font-size-16);
  line-height: var(--unnamed-line-spacing-24);
  letter-spacing: var(--unnamed-character-spacing-0-12);
  color: var(--unnamed-color-ffffff);
}
.unnamed-character-style-3 {
  font-family: var(--unnamed-font-family-montserrat);
  font-style: var(--unnamed-font-style-normal);
  font-weight: var(--unnamed-font-weight-medium);
  font-size: var(--unnamed-font-size-20);
  line-height: var(--unnamed-line-spacing-24);
  letter-spacing: var(--unnamed-character-spacing-0-15);
  color: var(--unnamed-color-ffffff);
}

.font-italic {
  font-style: italic;
}

.menubar .MuiDrawer-paper {
  margin-top: 80px;
  width: 445px;
  height: -webkit-fill-available;
}

.custom-accordion.MuiAccordion-root {
  box-shadow: none !important;
}

.MuiTab-textColorPrimary.Mui-selected {
  text-align: center;
  font: normal normal medium 12px/24px var(--font-montserrat);
  letter-spacing: 0.09px;
  color: #00b14f !important;
  opacity: 1;
}

.MuiFilledInput-root {
  border-radius: 0 !important;
}

.MuiFilledInput-underline:before {
  content: none !important;
}

.MuiFilledInput-underline:after {
  content: none !important;
}

.custom-slider .slick-slide {
  width: -moz-fit-content !important;
  width: fit-content !important;
}

/* input field padding  */
.item-find input,
.item-find .MuiSelect-root {
  /* padding: 0px 20px; */
  font: var(--font-montserrat);
  font-size: 12px;
}

.item-find-14 input,
.item-find-14 .MuiSelect-root {
  font-size: 14px;
}

.item-find input:hover,
.item-find .MuiSelect-root:hover {
  border-bottom: none;
}

.item-find .MuiInput-underline:before {
  border-bottom: none;
}

.item-find .MuiInputBase-input {
  padding: 6px 20px;
}

.custom-hero-btn.MuiButton-root:hover {
  background-color: #ffd200;
}

.custom-btn.MuiButton-root:hover {
  background-color: #00b14f;
}

.item-find .MuiInput-underline:after {
  border-bottom: none !important;
}

.item-find .MuiInput-underline:hover {
  border-bottom: none !important;
}

.item-find .MuiFilledInput-input {
  padding: 10px 20px !important;
}

/* .container-vrt-pd-0 .MuiContainer-root {
  padding-left: 0px !important;
  padding-right: 0px !important;
} */

.container-vrt-pd-0.MuiContainer-root {
  padding-left: 0px !important;
  padding-right: 0px !important;
}

.scroll-bar::-webkit-scrollbar {
  width: 10px;
  background-color: #ebebeb;
}

.scroll-bar-5::-webkit-scrollbar,
.scroll-bar-white::-webkit-scrollbar {
  width: 5px;
}

.scroll-bar-white::-webkit-scrollbar-track {
  background: #fff;
}

.scroll-bar::-webkit-scrollbar-thumb,
.scroll-bar-white::-webkit-scrollbar-thumb {
  background-color: #b8b6b6;
}

@media (max-width: 1280px) {
  .scroll-bar::-webkit-scrollbar {
    width: 4px;
  }

  .menubar .MuiBackdrop-root {
    margin-top: 60px;
  }

  .menubar .MuiDrawer-paper {
    margin-top: 60px;
    width: 445px;
  }
}

@media (max-width: 600px) {
  .scroll-bar::-webkit-scrollbar {
    width: 4px;
  }

  .menubar .MuiBackdrop-root {
    margin-top: 50px;
  }

  .menubar .MuiDrawer-paper {
    margin-top: 50px;
    width: 260px;
  }
}


/* link no decoration */
.no-decor {
  text-decoration: none !important;
  color: unset !important;
}

/* fix accordion wrong style on expanded */
.Mui-expanded:before {
  opacity: unset !important;
}

/* add style to accordion expandIconWrapper */
.MuiAccordionSummary-expandIconWrapper {
  background-color: rgba(0,255,0,0.5);
}
/* cyrillic-ext */
@font-face {
  font-family: '__Montserrat_4c800a';
  font-style: normal;
  font-weight: 100 900;
  font-display: swap;
  src: url(/_next/static/media/df4ba022c23c08de-s.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: '__Montserrat_4c800a';
  font-style: normal;
  font-weight: 100 900;
  font-display: swap;
  src: url(/_next/static/media/b1464bad92c88a2d-s.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* vietnamese */
@font-face {
  font-family: '__Montserrat_4c800a';
  font-style: normal;
  font-weight: 100 900;
  font-display: swap;
  src: url(/_next/static/media/8ed0c04f7e5d7b36-s.p.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__Montserrat_4c800a';
  font-style: normal;
  font-weight: 100 900;
  font-display: swap;
  src: url(/_next/static/media/c528baaebca50056-s.woff2) format('woff2');
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Montserrat_4c800a';
  font-style: normal;
  font-weight: 100 900;
  font-display: swap;
  src: url(/_next/static/media/1060bab20f18b5c2-s.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}@font-face {font-family: '__Montserrat_Fallback_4c800a';src: local("Arial");ascent-override: 84.95%;descent-override: 22.03%;line-gap-override: 0.00%;size-adjust: 113.95%
}.__className_4c800a {font-family: '__Montserrat_4c800a', '__Montserrat_Fallback_4c800a';font-style: normal
}.__variable_4c800a {--font-montserrat: '__Montserrat_4c800a', '__Montserrat_Fallback_4c800a'
}

